<template>
  <div class="Queryfeedback">
    <div class="Queryfeedback_h">
      <div class="Queryfeedback_bac"></div>
      <div class="Queryfeedback_box">
        <label class="label label_type">{{ queryTitle }}</label>
        <label class="label label_city">{{ queryType == 'continuingEducation' ? '全国' : queryCity }}</label>
        <div class="result_cont" v-if="form.queryResult">
          查询结果: {{ form.region }}
          <br>
          {{ form.queryResult }}
        </div>
      </div>
      <div class="Queryfeedback_box">
        <h4 class="title">填写信息</h4>
        <div class="Queryfeedback_form">
          <van-field
            v-model="form.feedbackPerson"
            :label="getFormLabel.feedbackPerson"
            placeholder="请输入您的姓名"
            :border="false"
            input-align="right"
            maxlength="100"
          />
          <van-field
            v-model="form.contactNumber"
            label="联系方式"
            placeholder="请输入您的电话或邮箱"
            :border="false"
            input-align="right"/>
          <van-field
            class="van-textarea"
            v-model="form.feedbackComments"
            :label="getFormLabel.feedbackComments"
            :placeholder="`请输入${getFormLabel.feedbackComments}`"
            type="textarea"
            :border="false"
            maxlength="2000"
            label-width="8em"
          />
        </div>
      </div>
      <div class="Queryfeedback_btns">
        <van-button class="btn_confirm" type="info" round @click="toConfirm">确认</van-button>
        <van-button class="btn_cancel" type="info" plain round @click="toBack">取消</van-button>
      </div>
    </div>
    <copyrightIp></copyrightIp>
  </div>
</template>

<script>
  import {submitFeedback} from "@/api/salaryQuery";
  import {Field, Button, Notify} from "vant";
  import copyrightIp from "@/components/copyrightIp";
  import resultData2Content from './js/resultData2Content'
  import {validatenull} from "@/utils/validate";
  
  export default {
    components: {
      [Field.name]: Field,
      [Button.name]: Button,
      copyrightIp
    },
    data() {
      return {
        queryType: '',
        queryTitle: '',
        queryCity: '', // 当为继续教育功能时的意见反馈时，所在城市为全国。
        form: {
          feedbackType: '',
          region: '',
          queryResult: '',
          feedbackPerson: '',
          contactNumber: '',
          feedbackComments: '',
        }
      }
    },
    created() {
      this.dataHandler()
    },
    methods: {
      // 数据处理
      dataHandler() {
        let result
        try {
          result = JSON.parse(sessionStorage.getItem('querSalaryResult'))
        } catch (error) {
          result = {}
        }
        
        const map = {
          corporatePension: "企业年金",
          dimissionAllowance: "经济补偿",
          housingFund: "住房公积金",
          disabledExemption: '残疾人',
          continuingEducation: '继续教育',
          housingRent: '住房租金',
          averageSalary: "平均工资",
          socialSecurityPayment: "社保缴纳",
          minimumWage: "最低工资",
          maternityLeave: '婚假产假',
          childCare: '子女护理假',
          highTemperatureAllowance: '高温津贴',
          transportationCommunication: '交通、通讯补贴',
          welfare: "优惠政策"
        }
        this.queryType = this.$route.params.queryType;
        this.queryTitle = map[this.$route.params.queryType]
        this.queryCity = this.queryType == 'welfare' ? result.title : result.city
        
        this.form.feedbackType = this.$route.params.queryCodeId
        this.form.region = this.queryType == 'welfare' ? result.title : result.city
        this.form.queryResult = resultData2Content(result, this.$route.params.queryType)
      },
      // 确认
      toConfirm() {
        if (!this.form.feedbackPerson) {
          return Notify(`请填写${this.getFormLabel.feedbackComments}`)
        }
        if (!this.form.contactNumber) {
          return Notify("请填写联系方式")
        }
        if (!this.form.feedbackComments) {
          return Notify(`请填写${this.getFormLabel.feedbackComments}`)
        }
        
        submitFeedback(this.form).then(res => {
          if (res.data.code == 0) {
            Notify({type: 'success', message: '提交成功'});
            this.toBack()
          } else {
            Notify(res.data.msg);
          }
        }).catch(e => {
          Notify('操作失败');
        })
      },
      // 返回
      toBack() {
        this.$router.back()
      }
    },
    computed: {
      getFormLabel() {
        return {
          feedbackPerson: this.queryType == 'welfare' ? '联系人' : '反馈人',
          feedbackComments: this.queryType == 'welfare' ? '您的需求或问题' : '反馈意见',
        }
      }
    }
  };
</script>


<style lang="scss" scoped>
  .Queryfeedback {
    width: 100%;
    height: 100%;
    position: relative;
    .Queryfeedback_h {
      min-height: calc(100% - 70px);
      padding: 18px 10px;
    }
    .Queryfeedback_bac {
      width: 100%;
      height: 220px;
      background-image: url("../../assets/img/query/bg1.png");
      background-size: 100%;
      background-repeat: no-repeat;
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
    }
    .Queryfeedback_box + .Queryfeedback_box {
      margin-top: 10px
    }
    .Queryfeedback_box {
      width: 100%;
      background: #fff;
      border-radius: 10px;
      padding: 10px 15px;
      .label {
        display: inline-block;
        vertical-align: middle;
        margin-right: 7px;
        margin-top: 5px;
        margin-bottom: 5px;
        padding: 0 10px;
        border-radius: 3px;
        font-size: 12px;
        line-height: 20px;
        font-weight: 500;
        border: 1px solid;
        &.label_type {
          color: #FF8C32;
          border-color: #FF8C32;
          background-color: #FFF8F2;
        }
        &.label_city {
          color: #4D83FF;
          border-color: #4D83FF;
          background-color: #F2F6FF;
        }
      }
      .result_cont {
        margin-top: 12px;
        font-size: 14px;
        color: #666;
        line-height: 20px;
      }
      .title {
        font-size: 16px;
        font-weight: bold;
        color: #333;
      }
    }
    .Queryfeedback_form {
      .van-field {
        padding: 12px 0;
        border-bottom: 1px solid #EBEBEB;
      }
      .van-textarea {
        display: block;
        padding-bottom: 0;
        border: none;
        ::v-deep textarea {
          margin-top: 10px;
          min-height: 75px;
          background: #F8F8F8;
          padding: 8px 15px;
          border-radius: 5px;
        }
      }
    }
    .Queryfeedback_btns {
      width: 325px;
      margin: 50px auto 0;
      display: flex;
      justify-content: space-between;
      .btn_confirm {
        width: 155px;
        background: linear-gradient(90deg, #4881F5, #5BACFC);
        font-size: 16px;
      }
      .btn_cancel {
        width: 155px;
        background: transparent;
        font-size: 16px;
      }
    }
  }
</style>
